import React, { Component } from 'react'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'

class Scheduler extends Component {
  render() {
    return (
      <>
        <div className='interfaceRow justifyCenter'>
          <Link to='/scheduler/monday/'>
            <FormattedMessage id='pages.scheduler.monday' />
          </Link>
        </div>
        <div className='interfaceRow justifyCenter'>
          <FormattedMessage id='pages.scheduler.tuesday' />
        </div>
        <div className='interfaceRow justifyCenter'>
          <FormattedMessage id='pages.scheduler.wednesday' />
        </div>
        <div className='interfaceRow justifyCenter'>
          <FormattedMessage id='pages.scheduler.thursday' />
        </div>
        <div className='interfaceRow justifyCenter'>
          <FormattedMessage id='pages.scheduler.friday' />
        </div>
        <div className='interfaceRow justifyCenter'>
          <FormattedMessage id='pages.scheduler.saturday' />
        </div>
        <div className='interfaceRow justifyCenter'>
          <FormattedMessage id='pages.scheduler.sunday' />
        </div>
      </>
    )
  }
}

export default Scheduler
