import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import Scheduler from '../components/operation/scheduler'
import SEO from '../components/seo'

const SchedulerPage = ({ intl }) => (
  <>
    <SEO title={intl.formatMessage({ id: 'pages.operation.scheduler' })} />
    <div
      style={{
        maxWidth: `800px`,
        margin: `0 auto`,
      }}
    >
      <Scheduler />
    </div>
  </>
)

export default injectIntl(SchedulerPage)
